import React from 'react'
import cbLogo from '../assets/cb_logo.png'

const Loader = () => {
    return (
        <div>
            <div className="flex items-center justify-center h-[70vh]">
                <div className="relative flex items-center justify-center">
                    {/* Spinning gradient line */}
                    <div
                        className="absolute w-40 h-40 border-[3px] border-transparent border-t-blue-500 border-r-purple-500 border-b-pink-500 border-l-indigo-500 animate-spin"
                        style={{
                            borderRadius: '50% 50% 70px 50%',
                        }}
                    />
                    {/* Stationary logo */}
                    <div className="relative w-36 h-36 rounded-full bg-white z-10 flex items-center justify-center">
                        <img
                            src={cbLogo}
                            alt="Connected Bytes Logo"
                            className="w-32 h-30 rounded-full"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Loader