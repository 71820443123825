import React from 'react'
import { useNavigate } from 'react-router-dom';

import './rendergutenberg.css'


const HomeSlider = ({ slider, cat }) => {
    const navigate = useNavigate();

    console.log(slider)

    const slug = slider.slider_image_link_home;
    const sliderimage = slider.slider_image_url_home;

    const pageNav = (link, cat) => {
        console.log(link)
        if (cat === 4) {
            navigate(`/products/${slider.slider_image_link_home.post_name}`)
        } else {
            navigate(`/case-studies/${slider.slider_image_link_home.post_name}`)
        }
    }



    return (
        <div>
            <div
                className='flex h-[350px] md:h-[400px] lg:h-[500px] bg-cover bg-right md:bg-contain'
                style={{
                    backgroundImage: `url(${sliderimage})`,
                    backgroundRepeat: "no-repeat"
                }}
            >
                <div className='w-1/2 md:w-1/3 flex flex-col px-[15px] py-[50px] items-start justify-center -m-[15px] bg-gradient-to-r from-white to-transparent backdrop-blur-[3px] md:bg-transparent md:backdrop-blur-[0px] rounded'>
                    <div className='ml-[20px] mb-[10px]' dangerouslySetInnerHTML={{ __html: slider.slider_name_home }} />
                    {
                        slug ?
                            <div>
                                <button
                                    className='ml-[20px] font-accent text-[16px] px-[10px] py-[5px] border-[1px] rounded border-primary'
                                    // onClick={() => navigate(`/slider/${slider.slider_image_link_home.post_name}`)}
                                    onClick={() => pageNav(slider.slider_image_link_home.post_name, slider.select_linked_post_category)}
                                >
                                    Read More
                                </button>
                            </div>
                            :
                            <div className='mt-[15px] h-[50px]' />
                    }
                </div>
            </div>
        </div>
    )
}

export default HomeSlider