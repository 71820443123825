import { useEffect, useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Splash from '../components/Splash';
import ImageLoader from '../components/ImageLoader';

const Products = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [posts, setPosts] = useState([])

    useEffect(() => {
        fetchData()
        window.scrollTo(0, 0);
    }, [])

    const fetchData = () => {
        axios.get('posts.php/get-posts')
            .then((response) => {
                const filteredPosts = response.data.filter(post => post.categories.includes(4));
                // console.log(filteredPosts)
                setPosts(filteredPosts)
                setLoading(false)
            })
            .catch((err) => { console.log(err) });
    }

    return (
        <>
            {
                loading ? <Splash /> :
                    <>
                        <div className='pb-[50px] pt-[15px] px-[5px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                            {
                                posts.map((post, index) => {
                                    return (
                                        <div key={index} className='border-secondarylight border-[2px] rounded p-[10px]'>
                                            <div>
                                                <ImageLoader url={post.acf.seo_image_posts} />
                                            </div>
                                            <div>
                                                <p className='font-secondary text-subheading'>{post.title.rendered}</p>
                                                <button
                                                    className='mt-[15px] font-accent text-[16px] p-[10px] border-[2px] rounded border-primary font-semibold'
                                                    onClick={() => navigate(`/products/${post.slug}`)}
                                                >
                                                    Read More
                                                </button>
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </>
            }
        </>
    )
}


export default Products