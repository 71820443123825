import { useEffect, useState } from 'react'
import axios from 'axios';
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import ImageLoader from '../components/ImageLoader';
import Loader from '../components/Loader';


const AllProducts = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true)
    const [posts, setPosts] = useState([])

    useEffect(() => {
        fetchData()
        window.scrollTo(0, 0);
    }, [])

    const fetchData = () => {
        axios.get('posts.php/get-posts')
            .then((response) => {
                const filteredPosts = response.data.filter(post => post.categories.includes(4));
                // console.log(filteredPosts)
                setPosts(filteredPosts)
                setLoading(false)
            })
            .catch((err) => { console.log(err) });
    }

    return (
        <>
            {
                loading ?
                    <Loader />
                    :
                    <>
                        <Helmet>
                            {/* Dynamic Open Graph Tags */}
                            <title>Products - ConnectedBytes</title>
                            <meta property="og:title" content='Products - ConnectedBytes' />
                            <meta property="og:description" content='Discover our products delivering innovation and real results through custom web, mobile, and enterprise applications' />
                            <meta property="og:image" content='https://utfs.io/f/wzoc0SJJ8iMqmv8tcTUCV0hUWxjMFoDS38r1AKmZ5kftGwsN' />
                            <meta property="og:url" content='https://connectedbytes.in/products' />
                            <meta property="og:type" content="article" />

                            {/* Additional Meta Tags */}
                            <meta name="twitter:card" content='Products - ConnectedBytes' />
                            <meta name="twitter:title" content='Products - ConnectedBytes' />
                            <meta name="twitter:description" content='Discover our products delivering innovation and real results through custom web, mobile, and enterprise applications' />
                            <meta name="twitter:image" content='https://utfs.io/f/wzoc0SJJ8iMqmv8tcTUCV0hUWxjMFoDS38r1AKmZ5kftGwsN' />
                        </Helmet>

                        <div className='page-container'>
                            <div className='pb-[25px] pt-[15px]'>
                                <p className='font-secondary text-heading text-center'>Products</p>
                                <div className='flex justify-center pb-[10px]'>
                                    <div className='w-[50px] border-[3px] border-secondary rounded' />
                                </div>
                            </div>

                            <div className='pb-[50px] pt-[15px] px-[5px] grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5'>
                                {
                                    posts.map((post, index) => {
                                        return (
                                            <div key={index} className='border-secondarylight border-[2px] rounded p-[10px]'>
                                                <div>
                                                    <ImageLoader url={post.acf.seo_image_posts} />
                                                </div>
                                                <div>
                                                    <p className='font-secondary text-subheading'>{post.title.rendered}</p>
                                                    <button
                                                        className='mt-[15px] font-accent text-[16px] p-[10px] border-[2px] rounded border-primary font-semibold'
                                                        onClick={() => navigate(`/products/${post.slug}`)}
                                                    >
                                                        Read More
                                                    </button>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </>
            }
        </>
    )
}

export default AllProducts