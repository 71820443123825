// import { useEffect, useState } from 'react'
import { Routes, Route } from 'react-router-dom';
import { Helmet } from "react-helmet";
import Header from './components/Header'
import Footer from './components/Footer';
import Home from './pages/Home';
import AllPosts from './pages/AllPosts';
import AllProducts from './pages/AllProducts';
import SinglePost from './pages/SinglePost';
import SingleProduct from './pages/SingleProduct';
// import ImageLoader from './components/ImageLoader';

function App() {

  const NotFound = () => {
    const images404 = [
      "https://emr.metadei.in/wp-content/uploads/2024/12/Resized_20200416_142905_108884348190285.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/IMG_2432.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/n02107908_7267.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/n02113023_2567.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/n02106166_4107.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/n02102318_234.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/n02085782_4436.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/hunghung.jpg",
      "https://emr.metadei.in/wp-content/uploads/2024/12/n02107574_2582.jpg"
    ];

    // Select a random image
    const randomImage = images404[Math.floor(Math.random() * images404.length)];

    return (
      <>
        <Helmet>
          <title>404 - Page Not Found</title>
        </Helmet>
        <div className="flex items-center justify-center min-h-screen bg-gray-100">
          <div className="text-center">
            <img
              src={randomImage}
              alt="Random 404"
              className="w-96 h-96 object-cover mx-auto mb-8 rounded-md"
            />
            <h1 className="text-6xl font-bold text-red-500">404</h1>
            <p className="mt-4 text-xl text-gray-700">
              Oops! The page you are looking for does not exist.
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {/* page frame */}
      <div className='flex flex-col'>

        {/* top menu */}
        <Header />

        {/* routing frame */}
        <Routes>
          <Route path='/' exact element={<Home />} />
          <Route path='/case-studies' element={<AllPosts />} />
          <Route path='/case-studies/:slug' element={<SinglePost />} />
          <Route path='/products' element={<AllProducts />} />
          <Route path='/products/:slug' element={<SingleProduct />} />
          <Route path='/slider/:slug' element={<SinglePost />} />
          <Route path="*" element={<NotFound />} />
        </Routes>


        {/* footer */}
        <Footer />

      </div >

    </>
  )
}

export default App
