import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App.jsx';
import { RecoilRoot } from 'recoil';
import axios from 'axios';
import { BrowserRouter as Router } from 'react-router-dom';

axios.defaults.baseURL = 'https://cms.connectedbytes.in/';
// axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('access_token');

const rootElement = document.getElementById('root');
const root = createRoot(rootElement); // React 18 createRoot method

root.render(
  // <Helmet>
  <Router>
    <RecoilRoot>
      <App />
      {/* <Loader /> */}
    </RecoilRoot>
  </Router>
  // </Helmet>
);
